import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Box from '@material-ui/core/Box';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    marginTop: 8
  },
  title: {
    fontSize: 14,
  },
  name: {
    fontWeight: 500,
  },
  pos: {
    marginTop: 4,
    marginBottom: 4,
  },
});

export default function RepCard({email, phone, message, name, type}) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} variant="outlined">
      <CardContent><>
      {!name &&
          <Box display="flex">
          {type === "Account Manager" && <SupervisorAccountIcon style={{marginRight: "10px"}} color="primary"/>}
          {type === "Order Processor" && <ShoppingBasketIcon style={{marginRight: "10px"}} color="primary"/>}
          {type === "Technical Contact" && <BuildIcon style={{marginRight: "10px"}} color="primary"/>}
        
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Your {type} has not been assigned yet.
        </Typography>
        </Box>
        }
          {name && <>
          <Box display="flex">
          {type === "Account Manager" && <SupervisorAccountIcon style={{marginRight: "10px"}} color="primary"/>}
          {type === "Order Processor" && <ShoppingBasketIcon style={{marginRight: "10px"}} color="primary"/>}
          {type === "Technical Contact" && <BuildIcon style={{marginRight: "10px"}} color="primary"/>}
        
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Your {type} is
        </Typography>
        </Box>
        <Typography className={classes.name} variant="body1" component="h4">
            {name}
        </Typography>
        <Typography className={classes.pos}>
          Phone: {phone}
        </Typography>
        <Typography className={classes.pos}>
          Email: <a href={`mailto:${email}`}>{email}</a>
        </Typography>
        <Typography variant="body2" component="p">
            {message}
        </Typography></>}
        </>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
