import React, { useEffect } from "react"
import {
  Link,
  graphql,
  useStaticQuery,
  navigate,
  prefetchPathname,
} from "gatsby"
import Img from "gatsby-image"
import Layout, { cedarGreen, cedarOrange } from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import {
  OndemandVideo,
  Receipt,
  ShoppingCart,
  Build,
  GetApp,
  AttachMoney,
  AccountCircle,
  AllInclusive,
  History,
} from "@material-ui/icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import IconButton from "@material-ui/core/IconButton"
import { useSelector, useDispatch } from "react-redux"
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab"
import {
  canAccessAgentApplication,
  hasApplicantRole,
  canAccessSizing,
} from "../utils/checkRoles"
import { Box } from "@material-ui/core"
import { getRep, getBannerImage } from "../state/store"
import { Image as CloudinaryImage } from "cloudinary-react"
import FeedbackText from "../components/feedback"
import RepCard from "../components/repCard"
import PublicHome from "../components/home/publicHome"
import { clearPumpCalculation } from "../state/store"
import { convertImageFromNocodb, getRoleTextOutput } from "../utils/common"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(-1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  featureIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconButtons: {
    display: "flex",
    justifyContent: "center",
  },
  bannerImage: {
    width: "100%",
  },
}))

const BannerImage = ({ banner }) => {
  const classes = useStyles()

  const noBanner = banner && banner.Picture && banner.Picture.length === 0

  const hasProduct = banner && banner.Picture && banner.Product.length > 0 && banner.ProductCode

  const hasLink = banner && banner.Link && banner.Link.length > 0

  return (
    <>

      {!noBanner && <div
        onClick={() => {
          if (hasProduct) {
            navigate(`/app/product/share/?id=${banner.Product[0].Id}`)
          } else if (hasLink) {
            navigate(banner.Link)
          }
        }}

        style={{
          cursor: hasProduct || hasLink ? 'pointer' : 'default'
        }}

      ><img alt={banner.Title} className={classes.bannerImage} src={convertImageFromNocodb(banner.Picture, 'tr:w-940,cm-pad_resize,bg-FFFFFF')} /></div>}

      {noBanner && <CloudinaryImage
        className={classes.bannerImage}
        cloudName="dt7kxyzky"
        publicId="https://res.cloudinary.com/dt7kxyzky/image/upload/v1689841422/Portal_Header_1_1_bvmbuy.png"
        secure="true"
        width="900"
        crop="scale"
      />}
    </>
  )
}

const IndexPage = () => {
  const classes = useStyles()
  const { loggedIn, userProfile, ready, rep, banner } = useSelector(
    state => state.db
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (ready && loggedIn) {
      prefetchPathname("/app/sizing/")
      dispatch(getRep())
    }
    if (ready) {
      dispatch(getBannerImage())
    }
  }, [loggedIn, ready, dispatch])


  return (
    <Layout>
      <SEO title="Home" />
      <Container component="main" maxWidth="lg">
        <div className={classes.paper}>
          {!ready && <CircularProgress />}
          {ready && !loggedIn && (
            <PublicHome />
          )}
          {ready && loggedIn && (
            <Container component="div" maxWidth="md">
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* <Image small="true" /> */}
                <div>
                  <Typography
                    component="h2"
                    variant="h6"
                    style={{ textAlign: "left" }}
                  >
                    Hi, {userProfile ? userProfile.firstName : ""}
                  </Typography>
                  {userProfile && canAccessSizing(userProfile.roles) && <Box
                    display="flex"
                  >
                    <Typography variant="body2">
                      Pricing:
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: "bold", marginLeft: "4px" }}>
                      {getRoleTextOutput({ roles: userProfile.roles })}
                    </Typography>
                  </Box>}
                </div>
                <div>
                  <Typography
                    component="p"
                    variant="body2"
                  >
                    Pickup Location (closest warehouse)
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    style={{ fontWeight: "bold", textAlign: "right" }}
                  >
                    {userProfile ? userProfile.warehouse : "Johannesburg"}
                  </Typography>
                </div>
              </div>
              <br />
              {userProfile && hasApplicantRole(userProfile.roles) && (
                <Alert
                  style={{ marginBottom: "1rem", width: "100%" }}
                  severity="warning"
                >
                  <AlertTitle>Agent Application Pending</AlertTitle>
                  Your application is pending please wait for our team to review it. Or email
                  support@cedarsolar.com for help.
                </Alert>
              )}
              {userProfile && canAccessAgentApplication(userProfile.roles) && (
                <Alert
                  style={{ marginBottom: "1rem", width: "100%" }}
                  severity="warning"
                >
                  <AlertTitle>Agent Application</AlertTitle>
                  To access the various features please fill out the agent
                  application{" "}
                  <Link
                    aria-label="Fill out application here"
                    to="/app/application/"
                  >
                    here
                  </Link>
                </Alert>
              )}
              <Box>
                {!banner && <div style={{ width: "100%", overflow: "hidden" }}><Skeleton variant="rect" width={912} height={312} /></div>}
                {banner && <BannerImage banner={banner} />}
              </Box>
              {userProfile && canAccessSizing(userProfile.roles) && (
                <Box>
                  {/* <Box display="flex" alignItems="center">
                    <Typography variant="h6">
                    Office Closure: Wednesday, 21 December 2021 (closing 16:00)<br/>
                    Office Re-Opening: Monday, 3 January 2022 (opening 08:00)
                    </Typography>

                  </Box> */}
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Pump Sizing"
                      onClick={() => {
                        dispatch(clearPumpCalculation())
                        navigate("/app/sizing/basic/")
                      }}
                    >
                      <Build htmlColor={cedarGreen} style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Pump Sizing</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Veichi Sizing"
                      onClick={() => navigate("/app/sizing/veichi/")}
                    >
                      <AllInclusive color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Veichi Sizing</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Saved Sizings"
                      onClick={() => navigate("/app/sizing/history/")}
                    >
                      <History color="primary" style={{ fontSize: "50px" }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Saved Sizings</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Training"
                      onClick={() => navigate("/app/training/")}
                    >
                      <OndemandVideo color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Training</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Downloads"
                      onClick={() => navigate("/app/downloads/")}
                    >
                      <GetApp color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Downloads</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Products"
                      onClick={() => navigate("/app/product/categories/")}
                    >
                      <ShoppingCart htmlColor={cedarOrange} style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Shopping</span>
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Products"
                      onClick={() => navigate("/app/products-order/")}
                    >
                      <ShoppingCart htmlColor={cedarOrange} style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Cart</span>
                    </div>
                  </div>
                </Grid> */}

                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Price List"
                      onClick={() => navigate("/app/price-list/")}
                    >
                      <AttachMoney color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Price List</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Support Ticket"
                      onClick={() => navigate("/app/support/")}
                    >
                      <Receipt color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Support Ticket</span>
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Profile"
                      onClick={() => navigate("/app/profile")}
                    >
                      <AccountCircle color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Profile</span>
                    </div>
                  </div>
                </Grid>  */}


                {/* <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Quote"
                      onClick={() => navigate("/app/quote/")}
                    >
                      <NoteAdd color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Quote</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={4} sm={3} className={classes.iconButtons}>
                  <div>
                    <IconButton
                      aria-label="Quote"
                      onClick={() => navigate("/app/quote/saved")}
                    >
                      <List color="primary" style={{ fontSize: 60 }} />
                    </IconButton>
                    <div style={{ textAlign: "center" }}>
                      <span>Saved Quotes</span>
                    </div>
                  </div>
                </Grid>

             */}


              </Grid>
              <br />
              <br />

              {userProfile && canAccessSizing(userProfile.roles) &&
                <Grid container spacing={2}>
                  {rep && rep.data &&
                    <Grid item xs={12} sm={4}>
                      <RepCard type="Account Manager" name={rep.data.name} phone={rep.data.phone} email={rep.data.email} message={`Your account manager is able to assist you in using the agent portal as well as product enquiries. Rep code: ${rep.data.code}`} />
                    </Grid>}
                  <Grid item xs={12} sm={4}>
                    <RepCard type="Order Processor" name="Yolanda Vince" phone="011 794 4664" email="office@cedarsolar.com" message="Your order processor is on hand to help you with orders, deliveries, payments and stock queries." />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <RepCard type="Technical Contact" name="Joburg Workshop" phone="011 794 4664" email="support2@cedarsolar.com" message="Technical support is available Monday to Friday 08:00 - 16:30. Troubleshooting guides available under Downloads section." />
                  </Grid>
                </Grid>}
              {/* <FeedbackText /> */}
            </Container>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
